@import "src/core/utils/theme/theme.scss";

.notifications-popup {
  @include themify($themes) {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 540px;
    height: 160px;
    padding: 24px;
    background-color: themed(secondaryColor);
    border-radius: 16px;
    animation: slide-in 0.5s;

    &.no-image {
      width: 400px; 
    }

    .notification-image {
      width: 188px;
      max-height: 100%;
    }

    .new-container {
      width: fit-content;
      background: themed(defaultBackground);
      border-radius: 4px;
      padding: 2px 6px;

      p.new {
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        color: themed(secondaryColor);
      }
    }

    p.title {
      margin-bottom: 0px;
      margin-top: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: themed(primaryColor);
    }

    p.desc {
      margin-top: 2px;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      color: themed(primaryColor);
    }

    .arrow-right {
      color: themed(primaryColor);
    }
  }

  &.slide-in-reverse {
    animation: slide-out 0.5s forwards;
    animation-direction: reverse;
  }

  @keyframes slide-in {
    0% {
      right: -400px;
    }
  }

  @keyframes slide-out {
    0% {
      opacity: 0;
      right: -400px;
    }
  }
}