@import "src/core/utils/theme/theme.scss";

.input-with-button-component {
  @include themify($themes) {
    input {
      background-color: themed(primaryColor);
      border-radius: 25px;
      padding: 15px;
      color: themed(textColor);

      &::placeholder {
        color: themed(textColor);
        font-weight: 300;
      }

      &:focus {
        border: 1px solid themed(secondaryColor);
        box-shadow: 0 0 5px themed(secondaryColor);
      }
    }
  }
}
