@import 'src/core/utils/theme/theme.scss';

.stats-card {
  @include themify($themes) {
    width: 100%;
    background: #101010;
    border-radius: 24px;
    margin-bottom: 32px;

    .stats-card-header {
      padding-top: 16px;
      padding-left: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #242323;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .stats-card-title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: themed(reportsTextColor);
      }

      .stats-card-subtitle {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: #f9c210;
      }
    }

    .stats-card-sub-header {
      padding-top: 24px;
      padding-left: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #242323;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 100px;

      .stats-card-sub-header-item {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .stats-card-sub-header-item-title {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #959896;
        }
        .stats-card-sub-header-item-value-text {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }

        .stats-card-sub-header-item-value {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
    }

    .stats-card-body-container {
      display: flex;
      flex-direction: row;

      .stats-card-body-left {
        width: 50%;
        padding-top: 24px;
        padding-right: 24px;
        padding-left: 32px;
        padding-bottom: 32px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-right: 1px solid #242323;

        .stats-card-body-left-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .stats-card-body-left-item-title {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #959896;
          }

          .stats-card-body-left-item-value {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            letter-spacing: -0.02em;
            color: #ffffff;
          }
        }
      }

      .stats-card-body-right {
        width: 50%;
        padding-top: 24px;
        padding-right: 24px;
        padding-left: 32px;
        padding-bottom: 32px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .stats-card-body-right-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .stats-card-body-right-item-title {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #959896;
          }

          .stats-card-body-right-item-value {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            letter-spacing: -0.02em;
            color: #ffffff;
          }
        }
      }
    }
  }
}
