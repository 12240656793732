@import "src/core/utils/theme/theme.scss";

.date-input-component {
  @include themify($themes) {
    padding: 15.5px 14px;
    border-radius: 16px;
    height: 53px;
    background-color: themed(thirdColor);
    border: 1px solid themed(gray6Color);
    color: themed(textColor);
    @extend .small-font;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }
}

.react-datepicker__triangle{
  transform: translate(100px, 0px) !important;
}