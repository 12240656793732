@import "src/core/utils/theme/theme.scss";

.landing-page {
  padding: 0px;
  height: 100%;
  background-color: #fff;

  .banner {    
    @include themify($themes) {
    background: themed(secondaryColor);
    padding: 20px 0px;

    .banner-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: themed(primaryColor);
        text-align: center;
        margin-bottom: 0px;

        @include media(phone) {
          font-size: 14px;  
        }
        
        .link {
          text-decoration: underline;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: themed(primaryColor);
          @include media(phone) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .main-bg {
    height: 100%;
    padding-bottom: 350px;
    overflow: visible;

    @include media (phone, tablet) {
      padding-bottom: 250px;
    }
  }

  .main-black-bg {
    height: 100%;
    overflow: visible;
  }
}
