.custom-tooltip {
  background-color: #000;
  .value-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-bottom: -5px;
  }
  .time-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}
