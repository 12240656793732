@import "src/core/utils/theme/theme.scss";

.retail-trader-card {
  @include themify($themes) {
    padding: 140px 120px;

    @include media(tablet) {
      padding: 120px 40px;
    }

    @include media(phone) {
      padding: 80px 20px;
    }
  
    .retail-title {
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      color: themed(primaryColor);
      margin-bottom: 60px;

      @include media (phone, tablet) {
        font-size: 32px;
        line-height: 42px;
      }      
    }

    .retail-feature {
      border-bottom: 1.5px solid #ECECEC;

      &.no-border {
        border: none;
      }
      
      .retail-feature-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: themed(primaryColor);
        text-align: start;
        padding: 20px 0px;

        img {
          margin-right: 32px;
          @include media (phone, tablet) {
            margin-right: 16px;
          }
        }

        @include media (phone, tablet) {
          font-size: 18px;
          line-height: 28px;
        }
      }
  
      .retail-feature-desc {
        font-weight: 350;
        font-size: 16px;
        line-height: 24px;
        color: themed(primaryColor);
        text-align: start;
        width: 60%;
      }
    }

  }
}