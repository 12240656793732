@import "src/core/utils/theme/theme.scss";

.step-card {
  @include themify($themes) {
    .step-number {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: themed(secondaryColor);
      margin-bottom: 12px;
    }

    .step-title {
      font-weight: 600;
      font-size: 48px;
      line-height: 54px;
      color: #181411;
      margin-bottom: 12px;

      @include media(phone, tablet) {
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
      }
    }

    &.dark {
      .step-title {
        color: #fff;
      }
    }

    .step-desc {
      font-size: 18px;
      line-height: 28px;
      color: #B0B0B0;
      margin-bottom: 48px;
      @include media(phone, tablet) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.dark {
      .step-desc {
        color: #959896;
      }
    }

    .early-access-button {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      padding: 12px 24px;
      background-color: themed(secondaryColor);
      border-color: themed(secondaryColor);
      color: themed(primaryColor);
      border-radius: 16px;
    }

    .second-card {
      margin-top: 100px;
      @include media(phone) {
        margin-top: 75px;
      }
    }
  }
}