@import "src/core/utils/theme/theme.scss";

.footer {
  @include themify($themes) {
    width: 100%;
    padding: 80px 32px;
    background: themed(defaultBackground);

    @include media (tablet) {
      padding: 80px 40px;
    }

    @include media (phone) {
      padding: 80px 20px;
    }

    .link {
      text-decoration: none;
    }

    .footer-top {
      padding-bottom: 32px;
      border-bottom: 1.5px solid themed(gray14Color);
      
      img.app-logo {
        height: 32px;
        margin-right: 32px;
      }
  
      p.nav-item {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: themed(reversePrimaryColor);
        margin-bottom: 0;
        cursor: pointer;
        @include media(phone) {
          font-size: 14px;
        }
      }
  
      .login-button {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 12px 24px;
        background-color: themed(secondaryColor);
        border-color: themed(secondaryColor);
        color: themed(primaryColor);
        border-radius: 16px;
      }
  
      .early-access {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 24px;
        border: 1px solid themed(secondaryColor);
        color: themed(secondaryColor);
        background: themed(glowBackground);
        border-radius: 16px;
        margin-right: 16px;
      }
    }

    .footer-bottom {
      margin-top: 32px;
      .desc {
        font-size: 13px;
        line-height: 24px;
        color: themed(descColor);
      }

      .social-logos {
        margin-bottom: 16px;

        img.social-logo {
          height: 40px;
          width: 40px;
          margin-right: 12px;
        }
      }

    }
  }
}