@import "src/core/utils/theme/theme.scss";

.sidebar-component {
  @include themify($themes) {
    background: themed(thirdColor);
    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding: 0px 20px 20px 20px;
    transition: all 0.5s ease;

    &.active {
      left: -225px;
    }

    .header {
      padding-top: 10%;
      padding-bottom: 10%;
      text-align: center;

      .app-logo {
        width: 50px;
        height: 50px;
      }

      .app-title {
        color: themed(secondaryColor);
        text-align: center;
        align-self: center;

        .title {
          @extend .mid-font;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
    }

    .links {
      margin-top: 8%;
      .link {
        &.logout-link {
          cursor: pointer;
          font-size: 14px;
        }
        p {
          font-weight: 500;
        }
        color: themed(gray2Color);
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          color: themed(secondaryColor);
        }
        &.active {
          color: themed(secondaryColor);
        }
      }
    }

    .copy-rights {
      position: absolute;
      bottom: 10px;
      color: themed(gray2Color);
      text-transform: uppercase;
      @extend .extra-small-font;
    }
  }
}
