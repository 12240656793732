@import 'src/core/utils/theme/theme.scss';

.filter-condition {
  @include themify($themes) {
    &_label {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

.filter-value-range {
  @include themify($themes) {
    padding: 10px 14px;
    border-radius: 16px;
    height: 52px;
    background-color: themed(thirdColor);
    border: 1px solid themed(gray6Color);
    color: themed(textColor);
    @extend .small-font;
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    .filter-value-range__input {
      width: 90%;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        background: themed(secondaryColor);
        cursor: pointer;
      }
    }
  }
}
