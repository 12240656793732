@import "src/core/utils/theme/theme.scss";

#order-details-modal-component {
  @include themify($themes) {
    .success {
      color: themed(successColor);
    }
    .text-end {
      p {
        font-weight: 600;
      }
    }
  }
}
