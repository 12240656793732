@import "src/core/utils/theme/theme.scss";

.clear-filter_btn {
  @include media(phone) {
  }

  @include themify($themes) {
    & {
      color: themed(textColor);
      border: 1px solid themed(thirdColor);
      background: none;
      background-color: transparent;
      padding: 2px 10px;
      border-radius: 7px;

      @extend .extra-small-font;

      display: flex;
      column-gap: 8px;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
