@import "src/core/utils/theme/theme.scss";

.success-page {
  @include themify($themes) {
    margin-top: 15%;
    text-align: center;

    .icon {
      color: themed(successColor);
      font-size: 100px;
    }
  }
}
