@import "src/core/utils/theme/theme.scss";

.notifications-page {
  @include themify($themes) {
    .notifications-tabs {
      justify-content: center;
      border: 1px solid themed(gray6Color);
      background-color: themed(thirdColor);
      padding: 4px;
      border-radius: 16px;

      a {
        padding: 12px;
        border-radius: 12px;

        &.active {
          background-color: themed(secondaryColor);
          border-color: themed(secondaryColor);

          p {
            color: themed(primaryColor) !important;
            font-weight: 300;
            font-size: 16px;
          }
        }

        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}