@import "src/core/utils/theme/theme.scss";

.input-with-add-button-component {
  @include themify($themes) {
    input {
      background-color: inherit;
      padding: 15px;
      color: themed(textColor);
      border: none;
      border-bottom: 1px solid themed(gray6Color);
      border-radius: 0;
      padding-left: 0px;

      &::placeholder {
        color: themed(gray6Color);
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
      }

      &:focus {
        border-bottom: 1px solid themed(secondaryColor);
        box-shadow: none;
      }
    }
    button {
      background-color: inherit;
      border: none;
      border-bottom: 1px solid themed(gray6Color);
      border-radius: 0;
      
      .add-button {
        color: themed(secondaryColor);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      &:focus {
        box-shadow: none;
        background: inherit;
      }
      &:hover {
        box-shadow: none;
        background: inherit;
        color: themed(secondaryColor);
        border-bottom: 1px solid themed(gray6Color);
      }
    }
    
    &.input-group:focus-within button {
      border-bottom: 1px solid themed(secondaryColor);
    }
  }
}