@import "src/core/utils/theme/theme.scss";

#default-modal-component {
  .modal-content {
    @include themify($themes) {
      border-radius: 24px;
      background: themed(modalBackground);

      .modal-body {
        padding: 45px;
      background: themed(modalBackground);
        .header {
          padding-bottom: 15px;
          .close-icon {
            @extend .large-font;
            color: themed(textColor);
            cursor: pointer;
            &:hover {
              opacity: 0.5;
            }
          }
        }
        hr {
          width: 100%;
          border: 1px solid themed(secondaryColor);
          opacity: 1;

          &.default {
            border: 1px solid themed(gray6Color);
          }
        }

        .content {
          padding-top: 15px;
        }
      }
    }
  }
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(27, 27, 27, 0.8);
  backdrop-filter: blur(6px);
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
}

// NOTE: This is a temporary fix for the nested modal z-index issue
@for $i from 1 through 6 {
    $zIndexBackdrop:  #{1000 + (5 * $i)};
    $zIndexContent:  #{1000 + (5 * $i) + 2};
    .modal-backdrop.show:nth-of-type(#{$i}) {
      z-index: $zIndexBackdrop;
    }
    div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
      z-index: $zIndexContent;
    }
  }