@import "src/core/utils/theme/theme.scss";

.trade-journal-winning-card {
  @include themify($themes) {
    padding: 24px;
    border-radius: 24px;
    background: themed(thirdColor);

    .winning-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      color: themed(reversePrimaryColor);
      margin-bottom: 8px;
    }
    .winning-desc {
      font-weight: 700;
      font-size: 24px;
      line-height: 23px;
      color: themed(textColor);
      margin-bottom: 0px;
      &.success {
        color: themed(successColor);
      }
      &.danger {
        color: themed(errorColor);
      }
    }

    .winning-icon {
      color: themed(infoContainer);
    }

    .bullet-container {
      .bullet-circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: themed(successColor);

        &.danger {
          background: themed(errorColor);
        }
      }

      .bullet-title {
        margin-bottom: 0px;
        font-size: 13px;
        line-height: 19px;
        color: #A7A7A7;
      }

      .bullet-desc {
        margin-bottom: 0px;
        font-weight: 700;
        font-size: 13px;
        line-height: 19px;
        color: #E2E2E2;
      }
    }

    .chart-container {
      width: fit-content;
      padding: 24px;
    }
  }
}

.chart-trades {
  @include themify($themes) {
    height: 150px;
    width: 150px;
    &-bar {
      .CircularProgressbar-path {
        stroke: themed(successColor);
      }
      .CircularProgressbar-trail {
        stroke: themed(errorColor);
      }
    }
    &-text {
      font-weight: 700;
      font-size: 32px;
      line-height: 23px;
      color: themed(successColor);
    }
  }
}