@import "src/core/utils/theme/theme.scss";
.table> :not(caption)>*>* {
  padding: 0
}

table.orders-table {
  margin-bottom: 0px;

  @include themify($themes) {
    &_header {
      color: themed(gray5Color)
    }

    @keyframes glow {
      0% { background: rgba(181, 173, 102, 0.3); }
    }

    tr {
      height: 100%;
      font-size: 12px;

      background: themed(thirdColor);
      border-bottom: 1px solid themed(tableHeaderColor);
      &.animate {
        animation: glow 3s;
      }
      td {
        font-weight: 300;
      }
    }

    thead {
      tr {
        font-size: 11px;
        background-color: themed(tableHeaderColor);
        th {
          padding-top: 8px;
          padding-bottom: 8px;
          color: #C0C0C0;

          &:first-of-type {
            padding-left: 24px;
            border-top-left-radius: 16px;
            overflow: hidden;
          }
          
          &:last-of-type {
            padding-right: 24px;
            border-top-right-radius: 16px;
            overflow: hidden;
          }

          span.sort {
            display: flex;
            flex-direction: column;
            align-items: center;

            padding-left: 2px;
            cursor: pointer;
          }
        }
      }
    }

    tbody {
      display: block;
      overflow: auto;

      tr {
        &:hover {
          background-repeat: no-repeat;
          background-size: cover;
          background: radial-gradient(rgba(181, 173, 102, 0.3), themed(thirdColor));
          cursor: pointer;
        }

        td {
          padding: 16px 0;

          &.premium {
            color: themed(successColor);
          }

          &:first-of-type {
            padding-left: 24px;
          }
        
          &:last-of-type {
            padding-right: 4px;
          }
        }
      }
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}
