@import 'src/core/utils/theme/theme.scss';

.trade-journal-net-chart {
  @include themify($themes) {
    // width: fit-content;
    padding: 24px;
    border-radius: 24px;
    background: themed(thirdColor);

    .net-chart-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.02em;
      color: #f4f4f4;
      margin-bottom: 0;
    }

    .learn-more {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: themed(secondaryColor);
      margin-bottom: 0;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition: 0.3s;
      }
    }
  }
}
