@import "src/core/utils/theme/theme.scss";

.general-settings-page {
  width: 60%;
  @include media(phone, tablet){
    width: 80%;
  }

  @include themify($themes) {
    
  }
}
