@import "src/core/utils/theme/theme.scss";

.info-banner-component {
  @include themify($themes) {
    background-color: themed(thirdColor);
    border-radius: 15px;

    .row {
      align-items: center;
      .message {
        .icon {
          margin-right: 10px;
          svg {
            font-size: 20px;
          }
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}
