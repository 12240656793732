@import "src/core/utils/theme/theme.scss";

.app-store-button {
  cursor: pointer;
  text-decoration: unset;
  background: #000000;
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid #A6A6A6;
  width: fit-content;
  p.app-store-button-title {
    color: white;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 0;
    line-height: 18px;

    @include media(phone) {
      font-size: 10px
    }

    span.store-name {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.05em;
      @include media(phone) {
        font-size: 16px
      }
    }
  }

  .app-store-icon {
    height: 30px;
    width: 25px;
    @include media(phone) {
      height: 37px;
      width: 23px;  
    }
  }
}