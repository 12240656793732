@import "src/core/utils/theme/theme.scss";

.notifications-wrapper {
  @include themify($themes) {
    background-color: themed(thirdColor);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 1px solid themed(fourthColor);
    cursor: pointer;
  }
}

.notifications-dropdown {

  &.hide {
    display: none;
  }

  @include themify($themes) {
    position: relative;
    height: 350px;
    width: 350px;
    z-index: 100;
    margin-bottom: -415px; // 350 + 75 - 15
    margin-left: -350px;
    background-color: themed(thirdColor);
    border: 1px solid themed(gray6Color);
    border-radius: 16px;
    overflow: hidden;

    .notifications-dropdown-footer {
      height: 50px; // 350 - 300
      padding: 12px 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 28px;
      color: themed(secondaryColor);
      border-top: 1px solid themed(gray6Color);
      cursor: pointer;

      p {
        font-weight: 600;
      }
    }
  }
}