.container-dev {
  padding-top: 32px;
  padding-bottom: 32px;
}

.main-row {
  gap: 16px;
}

.first-row-first-column {
  background: #101010;
  border-radius: 24px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 24px;
}

.second-row-first-column {
  padding-top: 16px;
  padding-bottom: 16px;
}

.first-row-second-column {
  background: #101010;
  border-radius: 24px;
  padding-top: 10px;
  padding-right: 2px;
  margin-bottom: 16px;
}

.second-row-second-column {
  width: 100%;
  background: #101010;
  border-radius: 24px;
  padding-top: 10px;
  padding-right: 2px;
  margin-bottom: 16px;
}

.chart-title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-right: 8px;
}

@import 'src/core/utils/theme/theme.scss';

.percentage {
  @include themify($themes) {
    font-family: 'Gilroy';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #f9c210;
    margin-bottom: 22px;
  }
  .small & {
    font-size: 16px;
    line-height: 24px;
  }
  .danger & {
    @include themify($themes) {
      color: themed(errorColor);
    }
  }
  .success & {
    @include themify($themes) {
      color: themed(successColor);
    }
  }
}
