@import "src/core/utils/theme/theme.scss";

.auth-header-component {
  @include themify($themes) {
    border-bottom: 1px solid themed(thirdColor);
    padding: 24px 32px;

    @include media(phone) {
      padding: 24px 20px;
    }

    &.no-bottom-padding {
      padding-bottom: 0px;
    }

    .right {
      text-align: right;
      justify-content: flex-end;
      p {
        margin-bottom: 0px;
      }
      .logout-icon {
        color: themed(secondaryColor)
      }
    }

    .register-steps-tabs-container {
      text-align: center;

      .tabs {
        justify-content: center;
        .nav-link {
          background-color: inherit;
          color: themed(gray5Color);
          border-radius: unset;
          border-bottom: 3px solid themed(gray5Color);
          text-align: center;
          padding-bottom: 20px;
          padding: 8px 24px 20px;
          margin-right: 5px;
          // min-width: 240px;

          &.active {
            color: themed(secondaryColor);
            font-weight: bold;
            border-bottom: 3px solid themed(secondaryColor);
          }

          .tab-icon{
            margin-right: 10px;
            font-size: 20px;
            @include media (phone) {
              margin-right: 0px;
            }
          }
        }
      }

      .section-content {
        margin-top: 3%;
      }
    }
  }
}
