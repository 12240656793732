@import "src/core/utils/theme/theme.scss";
.table> :not(caption)>*>* {
  padding: 0
}

table.daily-stats-table {
  white-space: nowrap;
  // max-width: 100%;
  margin-bottom: 0px;

  @include themify($themes) {
    &_header {
      color: themed(gray5Color)
    }

    tr {
      height: 100%;
      font-size: 12px;

      background: themed(thirdColor);
      border-bottom: 1px solid themed(tableHeaderColor);
      td {
        font-weight: 300;
      }
    }

    thead {
      tr {
        font-size: 11px;
        background-color: themed(tableHeaderColor);
        th {
          padding-top: 8px;
          padding-bottom: 8px;
          color: #C0C0C0;
        }
      }
    }

    tbody {
      display: block;
      overflow: auto;

      tr {
        &:hover {
          background-repeat: no-repeat;
          background-size: cover;
          background: radial-gradient(rgba(181, 173, 102, 0.3), themed(thirdColor));
          cursor: pointer;
        }

        td {
          padding: 16px 0;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: themed(textColor);
          
          &.success {
            color: themed(successColor);
          }
        }
      }
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}
