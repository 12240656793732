@import 'src/core/utils/theme/theme.scss';

.add-brokerage-connection-modal {
  @include themify($themes) {
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.02em;
      color: themed(textColor);
    }
    .desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: themed(descColor);
    }
    .connections-container {
      display: flex;
      justify-content: space-between;
      :not(:first-child, :last-child) {
        margin: 0px 12px;
      }
      .connection-box {
        padding: 16px;

        background: #1e1d1c;
        border: 1px solid themed(gray6Color);
        border-radius: 16px;

        cursor: pointer;

        &.selected {
          border: 1px solid themed(secondaryColor);
        }

        &.disabled {
          cursor: not-allowed;
        }

        .check {
          color: themed(secondaryColor);
        }

        .coming-soon {
          background: themed(gray6Color);
          border-radius: 30px;
          padding: 2px 10px;
          height: fit-content;
          p {
            margin-bottom: 0px;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            color: themed(gray5Color);
          }
        }

        .title {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #e5e5e5;
          margin-bottom: 0px;
          margin-top: 24px;
        }
      }
    }
  }

  .w-fit-content {
    width: fit-content;
  }
}

.add-brokerage-connection-modal-header {
  @include themify($themes) {
    .header-desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: themed(descColor);
      margin-bottom: 0px;
    }
  }
}

.clear-btn {
  background-color: #f65a6d !important;
  border: 0;
}

.cancel-btn {
  @include themify($themes) {
    color: #eac256 !important;
    border: 1px solid #1c1c1e !important;
  }

  &:hover {
    border: 1px solid #413f3a !important;
  }
}

.disconnect-btn {
  &:hover {
    border: 1px solid #eac256 !important;
  }
}
