@import "src/core/utils/theme/theme.scss";

.trade-journal-info-card {
  @include themify($themes) {
    padding: 16px;
    border-radius: 24px;
    background: themed(thirdColor);

    .info-img {
      height: 100%;
    }

    .info-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      color: themed(reversePrimaryColor);
      margin-bottom: 8px;
    }
    .info-desc {
      font-weight: 700;
      font-size: 24px;
      line-height: 23px;
      color: themed(textColor);
      margin-bottom: 0px;
      &.success {
        color: themed(successColor);
      }
      &.danger {
        color: themed(errorColor);
      }
    }

    .info-icon {
      color: themed(infoContainer);
    }
  }
}