@import 'src/core/utils/theme/theme.scss';

#custom-checkbox {
  @include themify($themes) {
    .checkbox-component {
      display: flex;
      align-items: center;

      input {
        background: none;
        border: 2px solid themed(textColor);
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-top: 0;
        cursor: pointer;

        margin-right: 10px;

        &:focus {
          box-shadow: none;
        }

        &:checked {
          background-color: themed(successColor);
        }
      }

      .text {
        @media screen and (max-width: 410px) {
          text-align: left;
          font-size: 0.7rem;
        }
      }
    }

    .error {
      color: themed(errorColor);
      margin-bottom: 0px;
      text-align: left;
    }
  }
}