@import "src/core/utils/theme/theme.scss";

.landing-top-bar {
  @include themify($themes) {
    width: 100%;
    padding: 32px;
    border-radius: 24px;

    @include media(phone) {
      padding-left: 20px;
      padding-right: 20px;
    }

    a {
      text-decoration: none;
    }

    img.app-logo {
      height: 32px;
      margin-right: 32px;
    }

    .menu-bars {
      color: themed(secondaryColor);
      cursor: pointer;
    }

    p.nav-item {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: themed(reversePrimaryColor);
      margin-bottom: 0;
      cursor: pointer;
    }

    .login-button {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      padding: 12px 24px;
      background-color: themed(secondaryColor);
      border-color: themed(secondaryColor);
      color: themed(primaryColor);
      border-radius: 16px;
    }

    .early-access {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 24px;
      border: 1px solid themed(secondaryColor);
      color: themed(secondaryColor);
      background: themed(glowBackground);
      border-radius: 16px;
      margin-right: 16px;
    }
  }
}

.landing-menu-mobile {
  @include themify($themes) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    height: calc(100vh - 96px - 64px);
    width: 100%;
    a {
      text-decoration: none;
    }
  
    p.nav-item {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: themed(reversePrimaryColor);
      margin-bottom: 20px;
      cursor: pointer;
    }

    .login-button {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      padding: 12px 24px;
      background-color: themed(secondaryColor);
      border-color: themed(secondaryColor);
      color: themed(primaryColor);
      border-radius: 16px;
    }

    .early-access {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 24px;
      border: 1px solid themed(secondaryColor);
      color: themed(secondaryColor);
      background: themed(glowBackground);
      border-radius: 16px;
      margin-right: 16px;
    }
  }
}