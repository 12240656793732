@import "src/core/utils/theme/theme.scss";

.default-tabs-component {
  @include themify($themes) {
    .tabs-container {
      text-align: center;
      margin-top: 1%;

      .tabs {
        .nav-link {
          background-color: inherit;
          color: themed(gray5Color);
          border-radius: unset;
          border-bottom: 3px solid themed(gray5Color);
          text-align: center;
          padding-bottom: 20px;
          margin-right: 5px;

          &.active {
            color: themed(secondaryColor);
            font-weight: 600;
            border-bottom: 3px solid themed(secondaryColor);
          }

          .tab-icon {
            svg{
              margin-right: 10px;
              font-size: 20px;
            }
          }
        }
      }

      .section-content {
        margin-top: 3%;
      }
    }
  }
}
