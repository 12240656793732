@import "src/core/utils/theme/theme.scss";

.billing-settings-page {
  width: 60%;
  @include media(phone, tablet) {
    width: 80%;
  }

  @include themify($themes) {
    .package-card {
      background-color: themed(thirdColor);
      border-radius: 15px;
      padding: 15px;
      text-align: left;

      .logo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        padding: 12px;
        background-color: themed(defaultBackground);
      }

      p {
        margin-bottom: 0px;
      }

      .seperator {
        border-color: themed(gray5Color);
      }

      .info {
        color: themed(secondaryColor);
        &.danger {
          color: themed(errorColor);
        }
        .danger {
          color: themed(errorColor);
        }
      }
    }
  }
}
