@import 'src/core/utils/theme/theme.scss';

.show-text {
  cursor: pointer;
  color: #f8c94f;
  font-weight: 500;
  text-decoration: underline;
}

.about {
  border-bottom: 1px solid #1b1b1b;
  margin-bottom: 24px;
}

.info-title {
  color: #959896;
  font-size: 14px;
}

.info-data {
  font-size: 12px;
}
