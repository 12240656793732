@import 'src/core/utils/theme/theme.scss';

.bar-chart-container {
  @include themify($themes) {
    padding: 24px;
    background: #101010;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    width: 49%;

    .bar-chart {
      margin-left: -10px;
    }

    .bar-chart-title {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: themed(reportsTextColor);
      margin-bottom: 4px;
    }

    .bar-chart-subtitle {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.02em;
      color: #f9c210;
      margin-bottom: 24px;
    }
  }
}
