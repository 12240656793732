@import "src/core/utils/theme/theme.scss";

.order-contract {
  border-radius: 7px;
  height: 24px;

  @include themify($themes) {
    padding: 4px 11px;
    &.call {
      background: themed(lightGreenBgColorRGBA);
      color: themed(successColor);
    }

    &.put {
      background: themed(lightErrorBgColorRGBA);
      color: themed(errorColor);
    }
  }
}