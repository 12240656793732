@import 'src/core/utils/theme/theme.scss';

.custom-date-picker-range {
  @include themify($themes) {
    .success{
        background-color: themed(successColor);
      }
      .danger{
        background-color: themed(errorColor);
      }
    .calendar {
      z-index: 200;
      background-color: themed(thirdColor);
      border: none;
      border-radius: 24px;
      overflow: hidden;

      // Styling for day tiles in month view
      .react-calendar__month-view__days {
        .tile {
          padding: 0px;
          background-color: themed(fourthColor);
          font-size: 11px;
          line-height: 16px;
          color: themed(textColor);
          border: 2px solid themed(thirdColor);
          .day {
            font-size: 12px;
            font-weight: 700;
          }
   
          .month {
            padding: 12px;
            &.not-current-month {
              opacity: 0.3;
            }
            &.primary {
              color: themed(secondaryColor);
            }
          }
          &:hover {
            background-color: themed(secondaryColor);
            p {
              color: themed(primaryColor) !important;
            }
          }
        }
      }

      .react-calendar__tile--range, .react-calendar__tile--hover {
        background-color: themed(secondaryColor) !important;
        p {
          color: themed(primaryColor) !important;
        }
      }
    }

    .date-picker-range-input {
      user-select: none;
      cursor: pointer;
      background: themed(thirdColor);
      border: 1px solid #1B1B1B;
      border-radius: 20px;
      padding: 16px 20px 16px 24px;

      &.short-version {
        padding: 12px 20px 12px 24px;
      }

      .placeholder-nodata {
        color: themed(gray6Color);
      }

      .input-text {
        padding: 0px!important;
        padding-right: 16px !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
      }
    }

    .popup-block {
      user-select: none;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: 1000;
      backdrop-filter: blur(2px);
    }
  }
}