@import "src/core/utils/theme/theme.scss";

.text-link-component {
  @include themify($themes) {
    text-underline-offset: 5px;
    text-decoration-color: themed(secondaryColor);

    p {
      color: themed(secondaryColor);
      font-weight: bold;
      @extend .small-font;
      &:hover{
        opacity: 0.8;
      }
    }
  }
}
