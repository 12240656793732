@import "src/core/utils/theme/theme.scss";

.login-container {
  .login-page {
    min-width: 50%;
    @include media(phone, tablet) {
      min-width: 90%;
    }
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2%;
    border-radius: 15px;
  }
  .forget-text{
    text-align: right;
  }
}
