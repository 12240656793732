@import "src/core/utils/theme/theme.scss";

.section-title-text-component {
  @include themify($themes) {
    color: themed(textColor);
    margin-bottom: 0px;
    line-height: 40px;
    &.sm {
      @extend .small-font;
    }
    &.md {
      @extend .mid-font;
    }
    &.lg {
      @extend .large-font;
    }
    &.secondary {
      color: themed(secondaryColor);
    }
    font-weight: 600;

    .icon {
      margin-right: 5px;
      svg {
        margin-top: -5px;
      }
    }
  }
}
