// for reference https://github.com/Veronika-Jaghinyan/light-dark-mode-app
@import './colors.scss';

//media types
$breakpointsMinWidth: (
  phone: 0px,
  tablet: 600px,
  laptop: 992px,
  desktop: 1200px,
);
$breakpointsMaxWidth: (
  phone: 599px,
  tablet: 991px,
  laptop: 1199px,
  desktop: 10000px,
);

//  mixin to print out media queries (based on map keys passed)
@mixin media($keys...) {
  @each $key in $keys {
    @media (min-width: map-get($breakpointsMinWidth, $key)) and (max-width: map-get($breakpointsMaxWidth, $key)) {
      @content;
    }
  }
}

//font-sizes
.extra-small-font {
  font-size: 0.9rem;
}
.small-font {
  font-size: 1rem;
}
.mid-font {
  font-size: 1.2rem;
}
.large-font {
  font-size: 2rem;
}
.extra-large-font {
  font-size: 3rem;
  @include media(phone) {
    font-size: 2rem;
  }
}

//theme color rendering
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@import "./animation.scss";