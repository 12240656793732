@import 'src/core/utils/theme/theme.scss';

@mixin colorize-card($color) {
  .chart-card_description {
    color: $color;
  }

  .chart-card_graph {
    &-bar {
      .CircularProgressbar-path {
        stroke: $color;
      }
    }
  }
}

.chart-card {
  @include themify($themes) {
    border-radius: 24px;
    background-color: themed(thirdColor);
    padding: 16px;
    padding-right: 0px;
    padding-left: 8px;

    margin-bottom: 16px;

    display: flex;
    align-items: center;

    &.no-background {
      background-color: unset;
      border: 1px solid themed(gray4Color);
    }

    &_body {
      margin-left: 12px;
      &.no-background  {
        margin-left: 8px;
      }
    }

    &_description {
      font-weight: 700;
      @extend .mid-font;
      line-height: 23px;
      margin-top: 4px;
    }

    &_graph {
      width: 64px;
      height: 64px;
    }

    .CircularProgressbar {
      &-trail {
        stroke: themed(primaryColor);
      }
    }

    &.error {
      @include colorize-card(themed(errorColor));
    }

    &.info {
      @include colorize-card(themed(infoColor));
    }

    &.primary {
      @include colorize-card(themed(primaryColor));
    }

    &.success {
      @include colorize-card(themed(successColor));
    }

    &.neutral {
      @include colorize-card(themed(descColor));
    }
  }

  p {
    margin: unset;
  }
}
