@import 'src/core/utils/theme/theme.scss';

.trade-journal {
  @include themify($themes) {
    padding: 32px;
    position: relative;
    min-height: 100%;
    p.journal-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      color: themed(textColor);
      margin-bottom: 0;
    }
    
    .add-goal-container {
      max-width: 355px;
      width: 100%;
    }

    .date-range-picker-container {
      max-width: 275px;
      width: 100%;
    }

    .flex-1 {
      flex: 1
    }
  }
}