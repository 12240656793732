@import "src/core/utils/theme/theme.scss";

.warning-banner-component {
  @include themify($themes) {
    background-color: themed(lightErrorBgColorRGBA);
    border-radius: 15px;
    padding: 15px;
    p {
      color: themed(errorColor);
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
}
