@import "~bootstrap/scss/bootstrap";
@import "src/core/utils/theme/theme.scss";

// FONT Gilroy
@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-ExtraBold.woff') format('woff');
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-ExtraBoldItalic.woff') format('woff');
  font-weight: bolder;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url('./assets/fonts/Gilroy-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

* {
  font-family: "Gilroy", sans-serif !important;
  font-weight: 300;
}

.form-control {
  font-weight: 350 !important;
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include themify($themes) {
    background-color: themed("defaultBackground");
    color: themed("textColor");
  }

  a {
    @include themify($themes) {
      color: themed("textColor");
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .w-fit-content {
    width: fit-content;
  }
}