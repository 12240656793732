@import "src/core/utils/theme/theme.scss";

.default-button-component {
  @include themify($themes) {
    width: 100%;
    padding: 15px;
    font-weight: 600;
    border-radius: 15px;
    background-color: themed(secondaryColor);
    border-color: themed(secondaryColor);

    &.small {
      padding: 6px 12px;
    }

    &.danger{
      background-color: themed(errorColor);
      border-color: themed(errorColor);
      color: themed(textColor)
    }

    &.secondary{
      background-color: #292D32;
      border-color: #292D32;
      color: themed(textColor)
    }

    color: themed(primaryColor);

    &:hover {
      color: themed(primaryColor);
      background-color: themed(secondaryColor);
      border-color: themed(secondaryColor);
      box-shadow: 0 0 5px themed(secondaryColor);
      &.danger{
        background-color: themed(errorColor);
        border-color: themed(errorColor);
        box-shadow: 0 0 5px themed(errorColor);
        color: themed(textColor)
      }
      &.secondary{
        background-color: #292D32;
        border-color: #292D32;
        box-shadow: 0 0 5px #292D32;
        color: themed(textColor)
      }
    }

    .spinner {
      color: themed(primaryColor);
      @extend .mid-font;
      animation: spin infinite 2s linear;
    }

    &.outlined {
      background-color: transparent;
      color: themed(secondaryColor);

      &:hover {
        color: themed(primaryColor);
        background-color: themed(secondaryColor);
        border-color: themed(secondaryColor);
      }
    }
  }
}
