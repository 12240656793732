@import "src/core/utils/theme/theme.scss";

.contact-us {
  @include themify($themes) {
    padding-top: 60px;

    .title {
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -0.02em;
      color: themed(textColor);
      margin-bottom: 12px;
    }

    .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.02em;
      color: themed(textColor);
      margin-bottom: 48px;
    }

    textarea {
      resize: none;
    }

    .input-container {
      margin-top: 12px;
    }

    .button-container {
      margin-top: 32px;
    }
  }
}

.contacted-us {
  margin: auto;
  @include themify($themes) {
    .success-icon {
      color: themed(successColor);
      margin-bottom: 32px;
    }

    .title {
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      color: themed(textColor);
      margin-bottom: 12px;
      text-align: center;
    }

    .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: themed(textColor);
      margin-bottom: 0px;
      text-align: center;
    }

    .button-container {
      margin-top: 32px;
    }
  }
}