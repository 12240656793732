@import "src/core/utils/theme/theme.scss";

.package-container {
  @include themify($themes) {
    width: fit-content;
    background: themed(thirdColor);
    border-radius: 24px;
    border: 1.5px solid themed(gray4Color);
  
    &.golden-border {
      border-color: themed(secondaryColor);
    }

    .package-title-container {
      padding: 18px;
      background: themed(defaultBackground);
      border: 1.5px solid themed(gray4Color);
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      
      &.golden-border {
        border-color: themed(secondaryColor);
      }
  
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: themed(secondaryColor);
      }
    }
  
    .top-container {
      padding: 32px 64px;
      border-bottom: 1.5px solid themed(gray4Color);

      @include media(phone) {
        padding: 32px 24px;
      }

      .offer-container {
        width: fit-content;
        display: inline-block;
        vertical-align: middle;
        padding: 4px 12px;
        background: #3EBC61;
        border: 1px solid rgba(62, 188, 97, 0.5);
        border-radius: 6px;

        .offer-text {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: -0.02em;
          color: themed(textColor);
        }
      }

            
      &.golden-border {
        border-color: themed(secondaryColor);
        padding-top: 0px;
      }
  
      .strike-through {
        position: relative;
        top: -40px;
        left: -10px;
        width: calc(100% + 20px);
        height: 2px;
  
        background: #F45B6C;
        transform: matrix(1, 0, 0, -1, 0, 0);
      }
  
      .old-price-container {
        padding: 12px;
        margin-bottom: 14px;
        background: rgba(149, 152, 150, 0.16);
        border: 1px solid rgba(149, 152, 150, 0.32);
        border-radius: 6px;
  
  
        .title {
          font-weight: 700;
          font-size: 32px;
          line-height: 24px;
          text-align: center;
          letter-spacing: -0.02em;
          color: themed(descColor);
        }
      }
  
      .price {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        color: themed(textColor);
        margin-bottom: 12px;
      }
  
      .desc {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: themed(descColor);
        margin-bottom: 0px;
      }
    }
  
    .bottom-container {
      padding: 32px;

      @include media(phone) {
        padding: 32px 24px;
      }
  
      .features-container {
        margin-bottom: 40px;
  
        .feature-row {
          margin-bottom: 16px;
  
          .feature-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: themed(textColor);
            margin-bottom: 0px;
          }
  
          .check-icon {
            width: 24px;
            height: 24px;
            color: themed(successColor);
            margin-right: 14px;
            @include media(phone) {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}