@import 'src/core/utils/theme/theme.scss';

.news-row {
  width: 100%;
  padding-top: 8px;
  .small & {
    padding-top: 12px;
  }
  cursor: pointer;

  @include themify($themes) {
    border-bottom: 1px solid themed(fourthColor);
  }

  .title {
    @include themify($themes) {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: themed(textColor);
      margin-bottom: 0px;
    }
    .small & {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .ticker {
    @include themify($themes) {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: themed(successColor);
      margin-bottom: 0px;
      &.danger {
        color: themed(errorColor);
      }
    }
    .small & {
      font-size: 13px;
    }
  }

  .caret {
    @include themify($themes) {
      position: relative;
      top: -1px;
      &.success {
        color: themed(successColor);
      }
      &.danger {
        color: themed(errorColor);
      }
    }
  }

  .time {
    @include themify($themes) {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: themed(gray5Color);
      margin-bottom: 0px;
    }
    .small & {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .desc {
    @include themify($themes) {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: themed(gray5Color);
    }
    .small & {
      font-size: 13px;
      line-height: 14px;
    }
  }
}

.news-list {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #1b1b1b;
    border-radius: 90px;
  }
  &::-webkit-scrollbar-thumb {
    background: #f8c94f;
    border-radius: 90px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #f8c94f;
    border-radius: 90px;
  }
}
