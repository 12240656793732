@import "src/core/utils/theme/theme.scss";

.file-input {
  @include themify($themes) {
    .file-drop {
      #input-file-upload {
        display: none;
      }
      &.drag-active {
        opacity: 0.5;
      }

      cursor: pointer;
      background-color: #232224;
      border: 1px dashed #454545;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;


      svg.upload-icon {
        path {
          stroke: #A4A8AD !important;
        }
      }

      .title {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: themed(descColor);
        margin-bottom: 0px;
        span {
          font-weight: 500;
          color: themed(textColor) !important;
        }
      }
      .choose-file {
        background: themed(grayColor);
        border-radius: 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #F9C210;
        box-shadow: none;
        border: none;
        padding: 6px 28px;
      }
    }
  }
}