.trade-tags-container {
    margin-bottom: 1rem;
    .trade-tag {
    margin-bottom: 1rem;
    }
    .trade-tag:not(:last-child) {
        margin-right: 0.5rem;
    }
}

h2 {
    color: #F8C94F;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 12px;
}