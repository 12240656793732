@import "src/core/utils/theme/theme.scss";

.register-page {
  .register-page-body {
    padding: 34px;
    text-align: center;
    @include media(phone, tablet) {
      padding: 34px 20px;
    }
  }
}