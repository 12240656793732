@import 'src/core/utils/theme/theme.scss';

.add-goal {
  @include themify($themes) {
    .add-goal-btn {
      background-color: themed(secondaryColor);
      border-color: themed(secondaryColor);
      border-radius: 20px;
      border-width: 0;
      padding: 16px 20px 16px 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 327px;

      &:hover {
        color: themed(primaryColor);
        background-color: themed(secondaryColor);
        border-color: themed(secondaryColor);
        box-shadow: 0 0 5px themed(secondaryColor);
        &.danger {
          background-color: themed(errorColor);
          border-color: themed(errorColor);
          box-shadow: 0 0 5px themed(errorColor);
          color: themed(textColor);
        }
        &.secondary {
          background-color: #292d32;
          border-color: #292d32;
          box-shadow: 0 0 5px #292d32;
          color: themed(textColor);
        }
      }

      &.outlined {
        background-color: transparent;
        color: themed(secondaryColor);

        &:hover {
          color: themed(primaryColor);
          background-color: themed(secondaryColor);
          border-color: themed(secondaryColor);
        }
      }
    }

    .add-goal-btn-text {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}

.modal-content {
  @include themify($themes) {
    border-radius: 24px;
    background: themed(modalBackground);

    .modal-body {
      padding: 45px;
      border-radius: 24px;
      background: themed(modalBackground);
      .header {
        padding-bottom: 15px;
        .close-icon {
          @extend .large-font;
          color: themed(textColor);
          cursor: pointer;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      hr {
        width: 100%;
        border: 1px solid themed(secondaryColor);
        opacity: 1;

        &.default {
          border: 1px solid themed(gray6Color);
        }
      }

      .content {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .add-goal-modal-body-text {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.02em;
          color: #959896;
        }

        .add-goal-input-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 16px 20px 16px 24px;
          gap: 16px;

          background: #1e1d1c;
          border: 1px solid #2d2d2d;
          border-radius: 20px;
          width: 100%;

          // focus
          &:focus-within {
            border: 1px solid #f8c94f;
            box-shadow: 0 0 5px #f8c94f;
          }

          //hover
          &:hover {
            border: 1px solid #f8c94f;
            box-shadow: 0 0 5px #f8c94f;
          }
        }

        .add-goal-input {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.02em;
          color: #ffffff;
          background: transparent;
          border: none;
          outline: none;
          width: 100%;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .add-save-goal-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 32px;
          background: #f8c94f;
          border-radius: 16px;
          width: 100%;

          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #181411;

          // disabled
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed !important;
          }

          &:hover {
            color: themed(primaryColor);
            background-color: themed(secondaryColor);
            border-color: themed(secondaryColor);
            box-shadow: 0 0 5px themed(secondaryColor);
            &.danger {
              background-color: themed(errorColor);
              border-color: themed(errorColor);
              box-shadow: 0 0 5px themed(errorColor);
              color: themed(textColor);
            }
            &.secondary {
              background-color: #292d32;
              border-color: #292d32;
              box-shadow: 0 0 5px #292d32;
              color: themed(textColor);
            }
          }

          &.outlined {
            background-color: transparent;
            color: themed(secondaryColor);

            &:hover {
              color: themed(primaryColor);
              background-color: themed(secondaryColor);
              border-color: themed(secondaryColor);
            }
          }

          border-width: 0;
        }
      }
    }
  }
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(27, 27, 27, 0.85);
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
}

// NOTE: This is a temporary fix for the nested modal z-index issue
@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.amount {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #181411;
}

.progress {
  width: 150px;
  height: 10px;
  margin: 0 10px;
  background-color: #24221e;
  padding: 3px;

  .progress-bar {
    height: 100%;
    background: #ffffff;
    border-radius: 90px;
  }
}
