@import "src/core/utils/theme/theme.scss";

.watchlist-desc {
    @include themify($themes) {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: themed(gray5Color);
    margin-bottom: 0px;
  }
}

#watchlist-details-modal-component {
  @include themify($themes) {
    .last-price-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: themed(textColor);
    }
    .last-price-desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: themed(textColor);
    }

    .watchlist-section-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: themed(textColor);
      margin-bottom: 8px;
    }

    .watchlist-section-desc {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: themed(gray5Color);
    }

    .watchlist-info-text {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: themed(gray5Color);
      margin-bottom: 0px;
    }

    .watchlist-default-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;      
      color: themed(textColor);
      margin-bottom: 0px;
    }

    span.primary {
      color: themed(secondaryColor);
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
    }

    hr {
      width: 100%;
      border: 1px solid themed(gray4Color) !important;
      opacity: 1;
      margin: 32px 0;
    }
  }
}