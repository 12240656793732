@import "src/core/utils/theme/theme.scss";

.page-header-component {
  @include themify($themes) {
    border-bottom: 1px solid themed(thirdColor);
    padding-top: 15px;
    padding-bottom: 15px;
    
    .left {
      p {
        margin-bottom: 0px;
      }
    }

    .right {
      justify-content: flex-end;
      p {
        margin-bottom: 0px;
      }
    }
  }
}
