@import 'src/core/utils/theme/theme.scss';

.page-layout {
  height: 100vh;
  @include themify($themes) {
    &.active {
      .section {
        margin-left: 0;
        width: 100%;
      }
    }
    .wrapper {
      height: 100%;
    }
    .section {
      height: 100%;
      width: calc(100% - 225px);
      margin-left: 225px;
      transition: all 0.5s ease;

      .main-page {
        flex-grow: 1;
      }

      .sidebar-opened {
        @include media(phone, tablet) {
          display: none;
        }
      }

      .top-navbar {
        height: 75px;
        border-bottom: 1px solid themed(thirdColor);
        align-items: center;
        padding-left: 10px;
        padding-top: 8px;
        padding-bottom: 8px;

        .left-section {
          display: inherit;
          align-items: center;
          align-self: center;
          .switch-sidebar-btn {
            padding: 5px;
            &:hover {
              border-color: themed(secondaryColor);
              box-shadow: 0 0 5px themed(secondaryColor);
              transition: all 0.5s ease;
            }
          }

          p.journal-profit {
            margin-bottom: 0px;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: themed(successColor);
            cursor: pointer;
          }
          p.account-selector {
            margin-bottom: 0px;
            font-size: 20px;
            line-height: 24px;
            color: themed(secondaryColor);
            cursor: pointer;
          }

          img.selected-account-icon {
            height: 20px;
            width: 20px;
          }
        }
        .right-section {
          display: inherit;
          justify-content: flex-end;
          align-items: center;
          align-self: center;
          p {
            padding-left: 10px;
            margin-bottom: 0px;
          }

          .settings-icon {
            cursor: pointer;
            font-size: x-large;
            &:hover {
              color: themed(secondaryColor);
            }
          }
        }

        .date-range-picker-container {
          max-width: 280px;
          width: 100%;
          margin-right: 24px;
        }

        .reports-text {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          letter-spacing: -0.02em;
          color: #ffffff;
          margin-bottom: 0 !important;
          margin-left: 1rem;
        }
      }
    }
  }
}
