@import "src/core/utils/theme/theme.scss";

.theme-btn-img-component {
  @include themify($themes) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
    animation-iteration-count: 3;
    border-radius: 50%;
  }
}
