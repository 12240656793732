@import "src/core/utils/theme/theme.scss";

.order-consolidation {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 24px;
  border-radius: 7px;
  padding: 8px 12px;

  font-weight: 600;

  @include themify($themes) {
    &.block {
      background: rgba(196, 196, 196, 0.9);
      color: themed(primaryColor);
    }

    &.sweep {
      background: rgba(62, 112, 188, 0.3);
      color: themed(infoColor);
    }

    &.unusualsweep {
      background: themed(successColor);
      color: themed(primaryColor);
      padding: 8px 4px !important;
      font-size: 11px
    }

    &.split {
      background: rgba(89, 175, 206, 0.3);
      color: themed(lightInfoColor);
    }

    &.large {
      background: rgba(39, 75, 163, 0.9);
      color: #EEE;
    }

    &.gold {
      background: rgba(248, 201, 79, 0.3) !important;
      color: themed(secondaryColor) !important;
    }

    img {
      padding-right: 4px;
    }
  }
}