@import "src/core/utils/theme/theme.scss";

.upgrade-package-card-component {
  @include themify($themes) {
    text-align: left;
    background-color: themed(thirdColor);
    border-color: themed(secondaryColor);
    border-radius: 20px;

    .popular {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      padding: 10px;
      text-align: center;
      color: themed(primaryColor);
      background-color: themed(secondaryColor);
      p {
        color: themed(primaryColor);
        margin: 0px;
      }
    }

    .card-header,
    .card-body,
    .card-footer {
      border-color: themed(gray4Color);
    }

    .proration-details{
      p {
        margin-bottom: 0px;
      }
      .icon {
        color: themed(secondaryColor);
      }
    }

    .package-header {
      .old-price {
        p {
          font-family: "AppFontLight" !important;
        }
      }
      .offer-price {
        padding-left: 10px;
        p {
          color: themed(successColor);
        }
      }
      .offer-text {
        p {
          color: themed(successColor);
        }
      }
      p {
        margin: 0px;
      }
    }

    .package-details {
      display: -webkit-box;
      .check-icon {
        margin-right: 10px;
        color: themed(successColor);
      }
    }
  }
}
