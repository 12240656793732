@import "src/core/utils/theme/theme.scss";

.loading-spinner-centered-component {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  text-align: center;

  @include themify($themes) {
    svg {
      color: themed(secondaryColor);
    }

    .spinner {
      @extend .extra-large-font;
      animation: spin infinite 2s linear;
    }
  }
}
