@import 'src/core/utils/theme/theme.scss';

.root-container {
  @include themify($themes) {
    .tabs {
      padding-left: 30px;
      padding-bottom: 2px;
      border-bottom: 1px solid themed(thirdColor);
      gap: 24px;

      .tab-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .tab {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: themed(reportsTabsTextColor);
          cursor: pointer;
          margin-bottom: 16px;
        }

        .tab-active {
          color: #f8c94f;
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;
          margin-bottom: 16px;
        }

        .tab-active-line {
          height: 2px;
          width: 100%;
          margin: 0 !important;
          border: 1px solid #f8c94f !important;
          opacity: 1 !important;

          animation: line 0.5s ease-in-out;
          @keyframes line {
            0% {
              width: 0%;
            }
            100% {
              width: 100%;
            }
          }
        }

        .tab-line {
          height: 2px;
          width: 100%;
          margin: 0 !important;
          border: 1px solid transparent !important;
          opacity: 1 !important;
        }
      }
    }

    .tab-content-container {
      padding: 0 30px;
      margin-top: 31px;
    }
  }
}
