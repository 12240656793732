@import "src/core/utils/theme/theme.scss";

.step-card {
  padding: 140px 120px;

  &.dark {
    padding: 200px 120px;
  }

  @include media(tablet) {
    padding-right: 40px!important;
    padding-left: 40px!important;
    padding-bottom: 0px;
  }

  @include media(phone) {
    padding-right: 20px!important;
    padding-left: 20px!important;
    padding-bottom: 0px;
  }
}