@import "src/core/utils/theme/theme.scss";

.secret-input-group-component-container {
  @include themify($themes) {
    .secret-input-group-component {
      .icon {
        background-color: themed(thirdColor);
        color: themed(gray5Color);
        border-color: themed(gray4Color);
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        font-size: 25px;
        &.error {
          color: themed(errorColor);
          border-color: themed(errorColor);
        }
      }

      .secret-input {
        background-color: themed(thirdColor);
        border-color: themed(gray4Color);
        border-left: none;
        color: themed(textColor);
        padding: 15px;
        padding-left: 0px;
        border-radius: 15px;

        &:focus {
          // border-color: themed(secondaryColor);
          // box-shadow: 0 0 5px themed(secondaryColor);
          box-shadow: none;
        }
        &::placeholder {
          color: themed(gray5Color);
          font-weight: 300;
        }
        &.error {
          border-color: themed(errorColor);
          box-shadow: 0 0 5px themed(errorColor);
        }
      }

      .switch-secret-input {
        background-color: themed(thirdColor);
        border-color: themed(gray4Color);
        svg {
          color: themed(gray5Color);
        }
      }
    }
    .error {
      color: themed(errorColor);
      margin-bottom: 0px;
      text-align: left;
    }
  }
}
