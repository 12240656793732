@import 'src/core/utils/theme/theme.scss';

.daily-stats-card {
  @include themify($themes) {
    padding: 24px;
    background: themed(thirdColor);
    border-radius: 24px;
    
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.02em;
      color: themed(reversePrimaryColor);
      margin-bottom: 0px;
    }

    .desc {
      font-size: 20px;
      line-height: 24px;
      color: themed(successColor);
      margin-bottom: 0px;
    }

    .negative {
      color: themed(errorColor);
    }

    .dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: themed(reversePrimaryColor);
    }

    .show-more {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: themed(secondaryColor);
      margin-bottom: 0;
    }

    .note-button {
      padding: 4px 24px 4px 16px;
      border-radius: 12px;
      border: none;
      background: themed(defaultBackground);
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: themed(secondaryColor)
    }

    .stats-table {
      border-radius: 16px;
      border: 2px solid themed(gray4Color);
      .stats-box {
        padding: 19px 10px;
        flex: 1 1;
        background: themed(defaultBackground);
        border: 2px solid themed(gray4Color);
        flex-grow: 1;
        &.br-tl {
          border-top-left-radius: 14px;
        }
        &.br-tr {
          border-top-right-radius: 14px;
        }
        &.br-bl {
          border-bottom-left-radius: 14px;
        }
        &.br-br {
          border-bottom-right-radius: 14px;
        }

        .title {
          font-size: 14px;
          line-height: 16px;
          color: #C0C0C0;
          margin-bottom: 4px;
        }
        .desc {
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          color: themed(reversePrimaryColor);
          margin-bottom: 0px;
        }
      }
    }

    .flex-1 {
      flex: 1
    }
  }
}