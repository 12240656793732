@import "src/core/utils/theme/theme.scss";

.notification-row {
  width: 100%;

  @include themify($themes) {
    border-bottom: 1px solid themed(fourthColor);
  }
  cursor: pointer;

  padding: 12px 0px;
  .small & {
    padding: 12px 20px;
  }

  &:hover {
    @include themify($themes) {
      background-color: themed(hoverColor);
    }
  }


  span.new-tag {
    @include themify($themes) {
      position: relative;
      top: -2px;
      background: themed(secondaryColor);
      border-radius: 6px;
      padding: 2px 4px;
  
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      color: themed(primaryColor);
      margin-right: 6px;
    }

    .small & {
      font-size: 11px;
      margin-right: 2px;
    }
  }

  span.new-fire {
    margin-right: 2px;
    .small & {
      margin-right: 0px;
    }
  }

  span.date {
    @include themify($themes) {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: themed(gray5Color);
      margin-left: 6px;
    }
    .latest & {
      @include themify($themes) {
        color: themed(secondaryColor);
      }
    }
    .small & {
      font-size: 13px;
    }
  }
  
  p.title {
    @include themify($themes) {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: themed(textColor);
      margin-bottom: 0px;
      padding: 0px !important;
    }

    .small & {
      font-size: 16px;
      line-height: 24px;
    }
  }

  p.desc {
    @include themify($themes) {
      margin-top: 8px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
      color: themed(gray5Color);
      padding: 0px !important;
    }
    .latest & {
      @include themify($themes) {
        color: themed(textColor);
      }
    }
    .small & {
      margin-top: 4px;
      font-size: 13px;
      line-height: 14px;
    }
  }
}
