@import "src/core/utils/theme/theme.scss";

.filter-value-input {
  @include themify($themes) {
    padding: 10px 14px;
    border-radius: 16px;
    height: 52px;
    background-color: themed(thirdColor);
    border: 1px solid themed(gray6Color);
    color: themed(textColor);
    @extend .small-font;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }
}