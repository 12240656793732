@import 'src/core/utils/theme/theme.scss';

.default-input-group-container {
  @include themify($themes) {
    .default-input-group {
      .icon {
        background-color: themed(thirdColor);
        color: themed(reversePrimaryColor);
        border-color: themed(gray4Color);
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        font-size: 25px;
        &.error {
          color: themed(errorColor);
          border-color: themed(errorColor);
        }
      }

      .default-input-component {
        background-color: themed(thirdColor);
        border-color: themed(gray4Color);
        border-left: none;
        color: themed(textColor);
        padding: 15px;
        border-radius: 15px;
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;

        // &.no-b-l {
        //   border-left: none;
        // }

        &:focus {
          // border-color: themed(secondaryColor);
          // box-shadow: 0 0 5px themed(secondaryColor);
          box-shadow: none;
        }
        &::placeholder {
          font-weight: 300;
          color: themed(gray5Color);
        }
        &.error {
          border-color: themed(errorColor);
          // box-shadow: 0 0 5px themed(errorColor);
        }
      }
    }
    .error {
      color: themed(errorColor);
      margin-bottom: 0px;
      text-align: left;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
}
