@import "src/core/utils/theme/theme.scss";

.default-text-component {
  @include themify($themes) {
    color: themed(textColor);
    margin-bottom: 0px;
    &.sm{
      @extend .small-font;
    }
    &.md{
      @extend .mid-font;
    }
    &.lg{
      @extend .large-font;
    }

    &.secondary{
      color: themed(secondaryColor);
    }
    &.danger{
      color: themed(errorColor);
    }
  }
}
