@import "src/core/utils/theme/theme.scss";

.feature-card {
  @include themify($themes) {
    margin-bottom: 24px;
  
    .feature-img {
      height: 68px;
      width: 68px;
      margin-right: 32px;
      @include media (phone) {
        height: 48px;
        width: 48px;
        margin-right: 16px;
      }
    }
  
  
    .feature-title {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: themed(textColor);
      @include media (phone) {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 4px;
      }
    }
  
    .feature-desc {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: themed(descColor);
      margin-bottom: 0px;
      @include media (phone) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}