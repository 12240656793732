@import "src/core/utils/theme/theme.scss";

.platform-card {
  padding-top: 140px;
  padding-bottom: 200px;
  padding-left: 12.5%;
  padding-right: 12.5%;

  @include media(phone) {
    padding: 80px 20px;
  }

  img {
    width: 100%;
  }

  .store-buttons {
    position: relative;
    bottom: 8vw;
    @include media(tablet) {
      bottom: 10vw;
    }
    @include media (phone) {
      width: fit-content;
      margin: auto;
      bottom: 50vw;
    }
  }
}