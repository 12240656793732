@import "src/core/utils/theme/theme.scss";

.top-movers-row {
  width: 100%;
  // padding-top: 8px;
  // .small & {
  //   padding-top: 12px;
  // }
  // cursor: pointer;
  @include themify($themes) {
    border-bottom: 1px solid #themed(fourthColor);
  }
  
  .tag {
    @include themify($themes) {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: themed(gray5Color);
        margin-bottom: 0px;
      }
    
      .small & {
        font-size: 12px;
        line-height: 16px;
      }
    }

  .title {
    @include themify($themes) {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: themed(textColor);
      margin-bottom: 0px;
    }
    .small & {
      font-size: 14px;
      line-height: 16px;    
    }
    &.danger {
      @include themify($themes) {
        color: themed(errorColor);
      }
    }
    &.success {
      @include themify($themes) {
        color: themed(successColor);
      }
    }
  }

  .graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    height: 100%;
    border-radius: 0px 8px 8px 0px;
    flex: none;
    order: 3;
    flex-grow: 0;

    .danger & {
      @include themify($themes) {
        background: themed(lightErrorBgColorRGBA);
      }
    }

    .success & {
      @include themify($themes) {
        background: themed(lightGreenBgColorRGBA);
      }
    }

    .line {
      @include themify($themes) {
        width: 100%;
        height: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .danger & {
        @include themify($themes) {
          background: themed(errorColor);
        }
      }
  
      .success & {
        @include themify($themes) {
          background: themed(successColor);
        }
      }
    }
  }

}
