@import 'src/core/utils/theme/theme.scss';

#filters-modal {
  .modal-content {
    @include themify($themes) {
      border-radius: 24px;
      background: themed(modalBackground);

      .modal-body {
        color: themed(secondaryColor);
        padding: 45px;
        .header {
          padding-bottom: 15px;
          .close-icon {
            @extend .large-font;
            color: themed(textColor);
            cursor: pointer;
            &:hover {
              opacity: 0.5;
            }
          }
        }
        hr {
          height: 1px;
          width: 100%;
          background: themed(secondaryColor);
          opacity: 1;
        }
        .actions {
          display: flex;
          justify-content: space-around;
          column-gap: 20px;

          margin-top: 20px;
        }
      }
    }
  }
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 200px;

  .label-div {
    margin-top: 30px;
    margin-bottom: 20px;
    align-self: flex-start;
  }

  .dropdown-toggle {
    background: #000;
    border-color: #f8c94f;
    border-radius: 16px;
    color: #f8c94f;
    padding: 0.8rem 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    width: 240px;

    &:hover {
      background: #000;
      border-color: #f8c94f;
      border-radius: 16px;
      color: #f8c94f;
      padding: 0.8rem 0.5rem;
      font-size: 1rem;
      font-weight: 300;
    }
  }

  .dropdown-menu {
    background-color: #000;
    border-color: #f8c94f;
    border-radius: 16px;
    color: #f8c94f;
    padding: 0.8rem 0.5rem;
    font-size: 1rem;
    font-weight: 300;
  }

  .dropdown-item {
    color: #f8c94f;
    font-size: 1rem;
    font-weight: 300;

    &:hover {
      color: #f8c94f;
      background-color: gray;
    }
  }
}
