@import 'src/core/utils/theme/theme.scss';

.custom-calendar {
  @include themify($themes) {
    .calendar {
      background-color: themed(thirdColor);
      border: none;
      border-radius: 24px;
      overflow: hidden;

      // Styling for day tiles in month view
      .react-calendar__month-view__days {
        .tile {
          cursor: default;
          padding: 0px;
          background-color: themed(fourthColor);
          font-size: 11px;
          line-height: 16px;
          text-align: right; 
          color: themed(textColor);
          border: 2px solid themed(thirdColor);
          &.short-version {
            text-align: center; 
          }
          .day {
            font-size: 12px;
            font-weight: 700;
          }
          .mb-text {
            margin-bottom: 2px !important;
          }
          .month {
            padding: 12px;
            &.not-current-month {
              opacity: 0.3;
            }
            &.success{
              background-color: themed(successColor);
            }
            &.danger{
              background-color: themed(errorColor);
            }
            &.primary {
              color: themed(secondaryColor);
            }
            &.short-version {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}