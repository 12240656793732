@import 'src/core/utils/theme/theme.scss';

.information-page {
  @include themify($themes) {
    margin-right: 25%;
    margin-left: 25%;
    @include media(phone, tablet) {
      margin-right: 10%;
      margin-left: 10%;
    }

    span.primary-link {
      cursor: pointer;
      color: themed(secondaryColor);
      text-decoration: underline;
    }

    .link {
      text-decoration: none;
    }
  }

  .name-row {
    @media screen and (max-width: 767px) {
      gap: 1rem;
    }
  }
}