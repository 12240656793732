@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body{
  @include themify($themes) {
    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 themed(secondaryColorRGBA);
      }
  
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px themed(secondaryColorRGBA);
      }
  
      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 themed(secondaryColorRGBA);
      }
    }
  }
}
