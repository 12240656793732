@import "src/core/utils/theme/theme.scss";

.landing-home {
  @include themify($themes) {
    padding-top: 100px;
    padding-left: 120px;
    padding-right: 32px;

    @include media (tablet) {
      padding-left: 80px;
    }

    @include media (phone) {
      padding-left: 20px;
      padding-right: 20px;
    }

    p.title {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 56px;
      line-height: 60px;
      letter-spacing: 0.02em;
      color: themed(textColor);
      span.primary {
        color: themed(secondaryColor);
        text-decoration: underline;
      }

      @include media(phone) {
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 0.02em;
      }
    }

    p.desc {
      font-size: 20px;
      line-height: 32px;
      color: themed(descColor);
      margin-bottom: 56px;
      @include media(phone) {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}