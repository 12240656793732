@import "src/core/utils/theme/theme.scss";

.add-brokerage-csv-modal {
  @include themify($themes) {
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.02em;
      color: themed(textColor);
    }
    .desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: themed(descColor);
    }
  }

  .w-fit-content {
    width: fit-content;
  }
}

.add-brokerage-csv-modal-header {
  @include themify($themes) {
    .header-desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: themed(descColor);
      margin-bottom: 0px;
    }
  }
}