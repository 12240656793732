@import "src/core/utils/theme/theme.scss";

.daily-stats-title-container {
  @include themify($themes) {
    .date {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: themed(textColor);
      margin-bottom: 8px;
    }
    .instrument {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.02em;
      color: themed(secondaryColor);
      margin-bottom: 0px;
    }

  }
}
.daily-stats-details-modal {
  @include themify($themes) {
    .net {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: themed(successColor);
      margin-bottom: 0px;
    }
  
    .net-desc {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: themed(successColor);
      margin-bottom: 0px;

    }
    
    .success {
      color: themed(successColor);
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: themed(descColor);
    }

    .desc {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      text-align: right;
      letter-spacing: -0.02em;
      color: themed(textColor);
      &.success {
        color: themed(successColor);
      }
      &.danger {
        color: themed(errorColor);
      }
    }

    .input-wrapper {
      max-width: 120px;
      .dollar-icon {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 0px;
      }
    }

    .danger {
        color: themed(errorColor);
      }

  }
}

.trade-tags-container {
    margin-bottom: 1rem;
    .trade-tag {
    margin-bottom: 1rem;
    }
    .trade-tag:not(:last-child) {
        margin-right: 0.5rem;
    }
}
