@import "src/core/utils/theme/theme.scss";

/* Calendar Styles */
// styling for navigation bar on top
.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  @include themify($themes) {
    &__labelText {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  
    color: themed(reversePrimaryColor);
  
    &:focus {
      background-color: themed(thirdColor) !important;
    }
  
    &:hover {
      background-color: themed(secondaryColor) !important;
    }
  }
}

.react-calendar__navigation__arrow {
  font-size: 35px;
  font-weight: 300;
}

// special styling for decade navigation bar on top
.react-calendar__navigation button:disabled {
  @include themify($themes) {
    background-color: themed(thirdColor);
  }
}

// styling for all tiles in all views (except month view because it is overrided by above styling)
.react-calendar__tile {
  @include themify($themes) {
    color: themed(reversePrimaryColor);
    background-color: themed(fourthColor);
    border: 2px solid themed(thirdColor);
  }
}

// styling for all tiles in all views ON hover (except month view)
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  @include themify($themes) {
    &:hover {
      background-color: themed(secondaryColor);
    }
  }
}

// styling for NOW tile in all views ON hover (except month view)
.react-calendar__year-view .react-calendar__tile--now,
.react-calendar__decade-view .react-calendar__tile--now,
.react-calendar__century-view .react-calendar__tile--now {
  @include themify($themes) {
    background-color: themed(secondaryColor) !important;
    color: themed(primaryColor) !important;
  }
}

// styling for Week days on top of month view
.react-calendar__month-view__weekdays__weekday {
  @include themify($themes) {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #C1C1C1;
  
    abbr {
      text-decoration: none;
      cursor: default;
    }
  }
}

.react-calendar__month-view__weekdays {
  text-transform: capitalize;
}

.react-calendar__month-view__days > .react-calendar__tile,
.react-calendar__month-view__weekdays__weekday {
  flex-basis: 20% !important;
  max-width: 20% !important;
}

.react-calendar__month-view__weekdays__weekday:nth-child(0),
.react-calendar__month-view__weekdays__weekday:nth-child(6) {
  display: none !important;
}

.react-calendar__month-view__days__day--weekend {
  display: none !important;
}