@import "src/core/utils/theme/theme.scss";

.filters-btn {
  @include themify($themes) {
    & {
      border: 1px solid themed(gray7Color);
      border-radius: 32px;

      font-weight: 300;
      color: themed(gray7Color);

      padding: 12px 24px;
      margin-right: 10px;
      min-width: 250px;
      display: flex;
      justify-content: space-between;

      cursor: pointer;

      .filter-icon{
        font-size: 25px;
      }
    }
  }
}