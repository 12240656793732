@import "src/core/utils/theme/theme.scss";

.form-agreement-modal {
  @include themify($themes) {
    background-color: themed(modalBackground);
    .desc-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: themed(gray5Color);
    }

    .title-text {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: themed(textColor);
    }

    .sub-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.02em;
      color: themed(textColor);

      span.primary {
        font-weight: 600;
        color: themed(secondaryColor);
      }
    }

    .checkbox-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: themed(textColor);
    }

    .w-fit-content {
      width: fit-content;
    }
  }
}