@import 'src/core/utils/theme/theme.scss';

.choose-brokerage-account-modal {
  @include themify($themes) {

    .account-option {
      cursor: pointer;
      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: themed(textColor);
        margin-bottom: 0px;
      }
      .icon {
        color: themed(secondaryColor);
      }  
    }

    hr.account-option-seperator {
      width: 40% !important;
      border: 1px solid themed(gray6Color) !important;
      opacity: 1 !important;
      margin: 30px auto;
    }

    .w-fit-content {
      width: fit-content;
    }
  }
}