@import 'src/core/utils/theme/theme.scss';

.watch-list-row {
  width: 100%;
  // padding-top: 8px;
  // .small & {
  //   padding-top: 12px;
  // }
  cursor: pointer;

  @include themify($themes) {
    border-bottom: 1px solid themed(fourthColor);
  }

  .symbol {
    @include themify($themes) {
      color: themed(textColor);
      font-weight: 350;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0px;
    }
    .small & {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .name {
    @include themify($themes) {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: themed(gray5Color);
      margin-bottom: 0px;
    }
    .small & {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .price {
    @include themify($themes) {
      color: themed(textColor);
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0px;
    }
    .small & {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .percentage {
    @include themify($themes) {
      color: themed(gray5Color);
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0px;
    }
    .small & {
      font-size: 16px;
      line-height: 24px;
    }
    .danger & {
      @include themify($themes) {
        color: themed(errorColor);
      }
    }
    .success & {
      @include themify($themes) {
        color: themed(successColor);
      }
    }
  }
}

.watch-list {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #1b1b1b;
    border-radius: 90px;
  }
  &::-webkit-scrollbar-thumb {
    background: #f8c94f;
    border-radius: 90px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #f8c94f;
    border-radius: 90px;
  }
}
