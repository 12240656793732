@import "src/core/utils/theme/theme.scss";

.value-selector-component {
  @include themify($themes) {
    .multi-select {
      &__control {
        background-color: themed(thirdColor);
        border-color: themed(gray6Color);
        height: 52px;
        border-radius: 16px;
        box-shadow: none;
        &:hover {
          cursor: pointer;
        }
      }
      &__input-container {
        color: themed(textColor);
      }
      &__placeholder {
        color: themed(gray5Color);
        @extend .extra-small-font;
      }
      &__multi-value {
        background-color: themed(secondaryColor);
        &__label {
          color: themed(primaryColor);
          @extend .extra-small-font;
        }
        &__remove {
          color: themed(errorColor);
        }
      }
      &__single-value {
        color: themed(reversePrimaryColor);
      }
      &__indicator-separator {
        display: none;
      }
      &__menu {
        background-color: themed(fourthColor);
      }
      &__option {
        background-color: themed(fourthColor);
        @extend .extra-small-font;
        color: themed(textColor);
        &:hover {
          background-color: themed(thirdColor);
          cursor: pointer;
        }
      }
    }
  }
}
