@import "src/core/utils/theme/theme.scss";

.journaling-card {
  @include themify($themes) {
    padding: 200px 120px 300px;

    @include media(tablet) {
      padding: 250px 40px 200px;
    }

    @include media(phone) {
      padding: 250px 20px 250px;
    }

    .journaling-img {
      @include media(phone, tablet) {
        margin-bottom: 60px;
      }
    }

    .journaling-title {
      margin-bottom: 80px;
      text-align: center;
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
      color: themed(textColor);
      @include media(phone, tablet) {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 60px;
      }
    }
  }
}