

.trade-tag {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #000;
    border: none;
 
    vertical-align: middle;
    &.strategy {
        background-color: #f5a623;
        color: #000;
        &:hover {
            cursor: pointer;
            background-color: #f5a62380;
            color: white;
    
        }
        &:active, &.active {
            background-color: #f5a62328;
            color: #fff;
        }
    }
    &.mistake {
        background: #FF484B;
        color: #fff;
        &:hover {
            cursor: pointer;
            background-color: #FF484B80;
            color: white;
    
        }
        &:active, &.active{
            background-color: #FF484B28;
            color: #fff;
        }
    }
   
    
}

