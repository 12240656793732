@import "src/core/utils/theme/theme.scss";

.select-package {
  @include themify($themes) {
    position: relative;
    .bull-img {
      position: absolute;
      bottom: 0;
      left: -32px;
      width: 18%;
    }

    .select-package-banner {
      border-radius: 32px;
      padding-top: 56px;
      padding-bottom: 150px;

      @include media(phone, tablet) {
        padding-right: 10px;
        padding-left: 10px;
      }

      @include media(phone) {
        padding-bottom: 56px;
      }

      @include media(tablet) {
        padding-bottom: 100px;
      }

      .prize-container {
        background: rgba(24, 20, 17, 0.08);
        border: 1px solid rgba(24, 20, 17, 0.16);
        border-radius: 8px;
        width: fit-content;
        padding: 12px 16px;

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: themed(primaryColor);
          margin-bottom: 0px;
          @include media(phone, tablet) {
            font-size: 14px;
          }
        }

        .gift-icon {
          color: themed(primaryColor);
          position: relative;
          top: -2px;
          margin-right: 8px;
        }
      }

      .page-title {
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        letter-spacing: -0.02em;
        color: themed(primaryColor);
        margin-top: 16px;
        margin-bottom: 16px;

        @include media (phone, tablet) {
          font-size: 40px;
          line-height: 40px;
        }

      }

      .page-desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.02em;
        color: themed(primaryColor);
        margin-bottom: 0px;

        @include media(phone, tablet) {
          font-size: 14px;
        }
      }
    }

    .package-containers {
      position: relative;
      top: calc(-24px - 18px - 18px - 4px);
    }

    .packages-tabs {
      justify-content: center;
      border: 1px solid themed(gray6Color);
      background-color: themed(thirdColor);
      padding: 4px;
      border-radius: 16px;

      a {
        padding: 12px;
        border-radius: 12px;

        &.active {
          background-color: themed(secondaryColor);
          border-color: themed(secondaryColor);

          p {
            color: themed(primaryColor) !important;
            font-weight: 500;
            font-size: 16px;
          }
        }

        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.select-package-page {
  margin-right: 20%;
  margin-left: 20%;

  @include media(phone, tablet) {
    margin-right: 8%;
    margin-left: 8%;
  }

  @include themify($themes) {
    .package-types-tabs {
      justify-content: center;
      border: 1px solid themed(gray4Color);
      background-color: themed(thirdColor);
      padding: 8px;
      border-radius: 15px;

      a {
        padding: 12px;
        border-radius: 15px;

        &.active {
          background-color: themed(secondaryColor);
          border-color: themed(secondaryColor);

          p {
            color: themed(primaryColor) !important;

            .save {
              font-weight: 700;
            }
          }
        }

        p {
          margin-bottom: 0px;
        }
      }
    }

    .package-card {
      text-align: left;
      background-color: themed(thirdColor);
      border-color: themed(secondaryColor);
      border-radius: 20px;

      .popular {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        padding: 10px;
        text-align: center;
        color: themed(primaryColor);
        background-color: themed(secondaryColor);

        p {
          color: themed(primaryColor);
          margin: 0px;
        }
      }

      .card-header,
      .card-body,
      .card-footer {
        border-color: themed(gray4Color);
      }

      .package-header {
        .old-price {
          p {
            font-weight: 300 !important;
          }
        }

        .offer-price {
          padding-left: 10px;

          p {
            color: themed(successColor);
          }
        }

        .offer-text {
          p {
            color: themed(successColor);
          }
        }

        p {
          margin: 0px;
        }
      }

      .package-details {
        display: -webkit-box;

        .check-icon {
          margin-right: 10px;
          color: themed(successColor);
        }
      }
    }
  }
}