@import "src/core/utils/theme/theme.scss";

.info-text-component {
  @include themify($themes) {
    color: themed(gray5Color);
    &.sm {
      @extend .small-font;
    }
    &.md {
      @extend .mid-font;
    }
    &.lg {
      @extend .large-font;
    }
    font-weight: 300;
  }
}
