@import "src/core/utils/theme/theme.scss";

.filter-chip {
  @include themify($themes) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;

    background-color: themed(thirdColor);
    border-radius: 7px;
    font-weight: 600;
    color: themed(primaryColor);
    @extend .extra-small-font;
    background-color: themed(secondaryColor);
  }
}
