@import 'src/core/utils/theme/theme.scss';

td {
  @include themify($themes) {
    color: themed(textColor);
    text-align: center;
    .progress {
      width: 90%;
      height: 6px;
      background: themed(gray6Color);
      padding: 0 !important;
      margin: 0 !important;

      &-bar {
        border-radius: 3px;
        background: themed(successColor);
      }
    }
  }
}
