@import "src/core/utils/theme/theme.scss";

.main-tabs, .secondary-tabs {

  @include themify($themes) {
    .nav-tabs {
      border: none
    }
  
    .nav-link {
      margin-right: 4px;
      border-radius: 16px 16px 0px 0px;
  
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
  
      color: themed(textColor);
      background-color: themed(fourthColor);
      border: none;
      &.active {
        background-color: themed(thirdColor);
      }
    }
  
    .tab-add.nav-link {
      color: themed(secondaryColor)
    }
  
    .tab-pane {
      background-color: themed(thirdColor);
    }
  }
}

.tab-pane {
  border-radius: 0 24px 0 0;
}