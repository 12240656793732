@import "src/core/utils/theme/theme.scss";

#notification-details-modal-component {
  .data {
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;

    @include themify($themes) {
      color: themed(gray5Color);
    }
  }
}